import React from "react";
import "../index.css"; // Add your custom CSS file to style the page

const Homepage = () => {
  return (
    <div className="homepage-container">
      <div className="left-column">
        <h2>Game Cartridges</h2>
        <div className="game-list">
          {/* Game cartridge items */}
          <div className="game-cartridge">
            <img src="path-to-game-icon.jpg" alt="Game Icon" className="game-icon" />
            <div className="game-info">
              <h3 className="game-title">Game Title</h3>
              <div className="max-players">4 Players</div>
            </div>
          </div>
          {/* Repeat above block for each game */}
        </div>
      </div>

      <div className="center-column">
        <h2>Game Sessions</h2>
        <div className="session-list">
          {/* Game session cards */}
          <div className="session-card">
            <img src="path-to-game-icon.jpg" alt="Game Icon" className="game-icon" />
            <div className="session-info">
              <p>Game Name</p>
              <p>Players: 3/4</p>
              <button className="join-btn">JOIN</button> {/* or PASSWORD button */}
            </div>
          </div>
          {/* Repeat above block for each session */}
        </div>
      </div>

      <div className="right-column">
        <h2>Friends</h2>
        <div className="friend-list">
          <div className="friend-group">
            <h3>Online</h3>
            <div className="friend">
              <div className="friend-status online"></div>
              <span>Friend Name</span>
            </div>
            {/* Repeat for each online friend */}
          </div>

          <div className="friend-group">
            <h3>Offline</h3>
            <div className="friend">
              <div className="friend-status offline"></div>
              <span>Friend Name</span>
            </div>
            {/* Repeat for each offline friend */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
