import React from "react";
import ReactDOM from "react-dom/client"; // For React 18
import { BrowserRouter } from "react-router-dom"; // Import React Router
import App from "./App"; // Ensure this path points to your App.jsx
import "./index.css"; // Import your CSS for styling

// Create the root React element
const root = ReactDOM.createRoot(document.getElementById("root"));

// Wrap App with BrowserRouter for routing
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
