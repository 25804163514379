import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/Login";
import Register from "./components/Register";
import HomePage from "./pages/Homepage"; // Ensure the path is correct

function App() {
  // Check if the user is logged in by checking for the auth token in localStorage
  const isLoggedIn = Boolean(localStorage.getItem("authToken"));

  return (
    <div className="App">
      <Routes>
        {/* Redirect to HomePage if logged in, otherwise go to /login */}
        <Route path="/" element={isLoggedIn ? <HomePage /> : <Navigate to="/login" />} />
        {/* Login and Register routes */}
        <Route path="/Login" element={isLoggedIn ? <Navigate to="/" /> : <Login />} />
        <Route path="/Register" element={isLoggedIn ? <Navigate to="/" /> : <Register />} />
        {/* Catch-all route for unmatched URLs */}
        <Route path="*" element={<Navigate to={isLoggedIn ? "/" : "/login"} />} />
      </Routes>
    </div>
  );
}

export default App;
